import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

const useScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.substring(1);

    if (hash) {
      setTimeout(() => {
        scroller.scrollTo(hash, {
          duration: 800,
          smooth: 'easeInOutQuad',
        });
      }, 2000);
    }
  }, [location]);

  return null;
};

export default useScrollToSection;