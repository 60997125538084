import './ThankYouPage.scss';
import { Link } from 'react-router-dom';

const ThankYouPage = () => {
    return (
        <main className='thank-you-page'>
            <h1>Thank you, your application has been sent successfully</h1>
            <h2>I will contact you shortly</h2>

            <Link to='/'>
                <button>Main page</button>
            </Link>
            
        </main>
    )
}

export default ThankYouPage
