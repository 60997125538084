import './FormSection.scss';
import Form from './Form/Form';
import FORM_PHOTO from '../../assets/form-section-image.webp';

const FormSection = () => {
    return (
        <section className='form-section' id='form'>
            <div className='form-section-container'>
                <h4 className='form-section-title-p1'>Book YOUR</h4>
                <h4 className='form-section-title-p2'>special date</h4>
                <span className='form-section-add-text'>Capture your happiest moments</span>

                <div className='form-section-image'>
                    <img src={FORM_PHOTO} alt={'form section'}/>
                </div>

                <div className='form-section-main-part'>
                    <Form/>
                </div>
            </div>
        </section>
    )
}

export default FormSection;
